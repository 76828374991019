import React from 'react'
import BoxStaticPageList, {
  BoxStaticPageListProps,
} from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'

const sectionsEs: BoxStaticPageListProps[] = [
  {
    title: 'Serviços para Profissionais do Transporte',
    items: [
      {
        title: 'Pneus Industriais',
        description: ``,
        img: `https://${process.env.RODI_AWS_BUCKET}/camiao/pneus-industriais.jpg`,
        route: 'camion.neumaticos',
      },
      {
        title: 'Assistencia 24h',
        description: ``,
        img: `https://${process.env.RODI_AWS_BUCKET}/camiao/assistencias-24h-camioes.jpg`,
        route: 'camion.asistencia',
      },
      {
        title: 'Gestão de frotas',
        description: ``,
        img: `https://${process.env.RODI_AWS_BUCKET}/camiao/gestao-frotas.jpg`,
        route: 'camion.gestion',
      },
    ],
  },
]

const CamionPt = () => (
  <LayoutLanding
    title="Serviços para Camião"
    descriptionCTA="Está à procura da melhor manutenção para veículos industriais? Na Rodi Motor encontrará à sua disposição um serviço de qualidade e uma vasta rede de oficinas."
    textButtonCTA="LOCALIZE A SUA OFICINA"
    linkButtonCTA="/oficinas-mecanicas/">
    <React.Fragment>
      <p>
        Na Rodi Motor oferecemos soluções para profissionais tais como
        assistência na estrada 24h, pneus industriais e a nossa própria
        plataforma de gestão de frotas. Temos uma rede de oficinas em Portugal
        que oferecem assistência personalizada para satisfazer as necessidades
        de cada cliente.
      </p>
      {sectionsEs.map((section) => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

export default CamionPt
