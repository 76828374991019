import { Link } from 'gatsby'
import React from 'react'
import route from '../../utils/route'
import styles from './boxStaticPageList.module.scss'

interface BoxImageTitlePropsBase {
  title: string
  img: string
  description: string
  route: string
}

interface ExternalItem extends BoxImageTitlePropsBase {
  external_url: true
  rel?: 'nofollow'
}
interface InternalItem extends BoxImageTitlePropsBase {
  external_url?: false
}

type BoxImageTitleProps = ExternalItem | InternalItem

export interface BoxStaticPageListProps {
  title: string
  items: BoxImageTitleProps[]
}

export default function BoxStaticPageList({
  title,
  items,
}: BoxStaticPageListProps) {
  return (
    <React.Fragment>
      <h2>{title}</h2>
      <div
        className={
          items.length % 2 === 0
            ? `${styles.items_wrapper} ${styles.items_even_wrapper}`
            : `${styles.items_wrapper} ${styles.items_odd_wrapper}`
        }>
        {items.map(item => (
          <div key={item.title} className={styles.item}>
            {item.external_url ? (
              <a href={item.route} rel={item.rel}>
                <img src={item.img} alt={item.title} />
                <h4>{item.title}</h4>
                <span>{item.description}</span>
              </a>
            ) : (
              <Link to={route(item.route)}>
                <img src={item.img} alt={item.title} />
                <h4>{item.title}</h4>
                <span>{item.description}</span>
              </Link>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}
